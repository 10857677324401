import React, { Component } from "react";
import StorebuilderForm from "./views/frontend/StorebuilderForm";
import Index from "./views/frontend/Index";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import "./component/Stepper/stepper.css";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Index />} />
            <Route path="/start-building-brand" element={<StorebuilderForm />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
