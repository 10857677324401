import React, { Component } from "react";
import StepperCompo from "./Stepper/StepperCompo";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import GuidLinePopup from "./Popup/GuidLinePopup";
import PlanPopup from "./Popup/PlanPopup";
import SubscribePopup from "./Popup/SubscribePopup";
import AccessStorePopup from "./Popup/AccessStorePopup";
import StorenamePopup from "./Popup/StorenamePopup";
import yes_img from "../images/yes.png";
import Multiselect from "multiselect-react-dropdown";
import TooltipCompo from "./Tooltip/TooltipCompo";
import font1 from "../fonts/font1.css";


class AccessStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitError: "",
      successBox: false,
      storeError: "",
      storenameError: "",
      errorSelectOption: "",
      loading: false,
      disabled: true,
      selectedOptions: [],
    };
  }

  // continue = (e) => {
  //   e.preventDefault();
  //   console.log(this.props);
  //   // this.props.nextStep();
  // };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      access_token,
      handleChange,
      storename,
      sendDataToParent,
      handleData,
      pickThemeError,
      themFileSubmit,
      productStoreSubmit,
      submitStoreData,
    } = this.props;

    const onSelect = (selectedList, selectedItem) => {
      if (selectedList.length <= 4) {
        this.setState({ 
          selectedOptions: selectedList,
          errorSelectOption: "",
         });

        handleData({
          selectedOptions: selectedList,
        });

      }else{
        this.setState({
          errorSelectOption: "You can only select up to 4 options.",
        });
      }
    };

    // Callback function to handle unselection change
    const onRemove = (selectedList, removedItem) => {
      this.setState({ selectedOptions: selectedList,errorSelectOption: "" });
      handleData({
        selectedOptions: selectedList,
      });
    };

    const handleNextStep = async (e) => {
      e.preventDefault();
      this.setState({ loading: true });
      const response = await submitStoreData(e);
      console.log(response);
      if (response.status === "success") {

        await productStoreSubmit(e);
        const obj = this;
        setTimeout(function(){

          obj.setState({
            loading: false,
            submitError: "",
          });
  
          obj.props.nextStep();
  
        },50000)
      
      } else {
        this.setState({
          loading: false,
          submitError: "something wrong! please check your store detail.",
        });
      }
    };

    const submitAccessToken = async (e) => {
      e.preventDefault();

      this.setState({ loading: true });
      if (!this.state.access_token) {
        this.setState({
          storeError: "Store access token required.",
        });
      } else {
        this.setState({
          storeError: "",
        });
      }

      if (!storename) {
        this.setState({
          storenameError: "Store name required.",
        });
      } else {
        this.setState({
          storenameError: "",
        });
      }

      if (this.state.access_token && storename) {
        handleData({
          access_token: this.state.access_token,
        });

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_FRONTEND_URL}api/get/store?storename=${this.props.storename}&access_token=${this.state.access_token}`,
            config
          );

          if (response.data.status === "error") {
            this.setState({ storeError: response.data.message });
            this.setState({ loading: false });
          } else {
            // this.props.nextStep(); success
            if (response.data.status === "success") {
              const apiResponse = await themFileSubmit(e);
              
              if (apiResponse.status == "success") {
                this.setState({
                  disabled: false,
                  loading: false,
                  successBox: true,
                  storeError: "",
                });
              } else {
                this.setState({
                  loading: false,
                  storeError: apiResponse.message,
                  disabled: false,
                  successBox: false,
                });
              }
            } else {
              this.setState({
                loading: false,
                successBox: false,
                storeError: "",
              });
            }
          }
        } catch (error) {
          // Handle errors from await
          this.setState({ loading: false });
          console.log(error);
        }
      } else {
        console.log(storename, "=====");
        console.log(this.state.access_token);
        this.setState({ loading: false });
      }
    };

    const handleAccessToken = async (e) => {
      this.setState({
        access_token: e.target.value,
      });
    };

    const productCategoryObj = [
      "Baby Products",
      "Childcare Niche",
      "Beauty and Skincare Niche",
      "Health & Wellness Niche",
      "Tech and Gadgets Niche",
      "Home Decor Niche",
      "Fashion and Accessories Niche",
      "Pet Supplies Niche",
      "Home Office and Productivity Niche",
      "Travel and Outdoor Adventure Niche",
    ];

    const textTool = "Add the initial store name, you can get this from Shopify settings screen and see your store name mentioned below My Store - i.e. 3ebee1.myshopify.com"

    return (
      <div className="form">
        <link
          rel="stylesheet"
          href={font1}
        />
        <form>
          <StepperCompo count={1} />
          <div className="container_card">
            <h4>Store Builder Form</h4>
            <div className="form-group step2_formgroup">
              <label className="form-group__label">
                1. Create your account on Shopify <TooltipCompo text="First you need to create an account on shopify for free, follow the given steps." tooltipId="info-tooltip" />
                </label>
              <ul>
                <li>
                  <span className="material-symbols-outlined">
                    double_arrow
                  </span>
                  Click the button below:
                </li>
                <li>
                  <AccessStorePopup handleData={handleData} />
                </li>
                <li>
                  <span className="material-symbols-outlined">
                    double_arrow
                  </span>
                  Enter your email address and click <strong>Start Free Trial</strong>
                </li>
                <li>
                  <span className="material-symbols-outlined">
                    double_arrow
                  </span>
                  Follow the steps to complete your registration.
                </li>
              </ul>
            </div>

            <div className="div_business">
              <label className="form-group__label">
                2. Enter Store Name <TooltipCompo text={textTool} tooltipId="info-tooltip" />
                <StorenamePopup handleData={handleData} />
                {/* <span className="notesBox">
                  ( Add the initial store name, you can get this from Shopify
                  settings screen and see your store name mentioned below My
                  Store - 
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://i.imgur.com/M6Uea3K.png"
                  >
                    <b>3ebee1</b>
                  </a>
                  .myshopify.com )
                </span> */}
              </label>
              <input
                type="text"
                value={storename}
                name="storename"
                onChange={handleChange("storename")}
                className="form-group__input"
              />
              <p className="error">{this.state.storenameError}</p>
            </div>

            <div className="div_business">
              <label className="form-group__label">
                3. Select Product Category
              </label>
              {/* <select
                id="input-font"
                name="Products"
                className="form-group__input"
              >
                <option value=""> -- Choose Product Category -- </option>
                {productCategoryObj.map((product_category) => (
                  <option value={product_category}>{product_category}</option>
                ))}
                ;
              </select> */}
              <Multiselect
                options={productCategoryObj}
                isObject={false} // Set to false because options are strings
                onSelect={onSelect}
                onRemove={onRemove}
                selectedValues={this.state.selectedOptions}
                displayValue="Product Categories"
                maxLimit="5"
              />
                <p className="error">{this.state.errorSelectOption}</p>
            </div>

            <div className="div_business sub-plan-card">
              <label className="form-group__label">
              4. Subscribe to a basic plan for $1/month 
                <PlanPopup handleData={handleData} />
              </label>
              <SubscribePopup handleData={handleData} />
              
            </div>

            <div className="div_business">
              <label className="form-group__label">
                5. Add API Access Token <TooltipCompo text="Basic plan is required to get the customizations" tooltipId="info-tooltip" />
                {/* <span className="notesBox">
                  ( Basic plan is required to get the customizations ){" "}
                </span> */}
                <GuidLinePopup handleData={handleData} />
              </label>
              <input
                type="text"
                value={this.state.access_token}
                name="access_token"
                onChange={(e) => handleAccessToken(e)}
                className="form-group__input"
              />
              <p className="error">{this.state.storeError}</p>
            </div>

            <div className="verify_token">
              <div>
                <button
                  className="buttons__button buttons__button--next"
                  onClick={(e) => submitAccessToken(e)}
                >
                  Verify Token
                </button>
              </div>
              {this.state.successBox && (
                <img
                  src={yes_img}
                  className="brandbldr-icon"
                  alt="enter credit card"
                />
              )}
            </div>

            <div className="div_business">
              <p className="error">
                {this.state.submitError && this.state.submitError}
              </p>
            </div>

            {this.state.loading && (
              <TailSpin
                height="80"
                width="80"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="custom-loader"
              />
            )}

            {/* <PickTheme
              handleChange={handleChange}
              sendDataToParent={sendDataToParent}
              handleData={handleData}
              pickThemeError={pickThemeError}
              themFileSubmit={themFileSubmit}
            /> */}
          </div>

          <div style={{ textAlign: "center" }} className="buttons footer_btn">
            <button
              className="buttons__button buttons__button--back"
              onClick={this.back}
            >
              Back
            </button>
            <button
              disabled={this.state.disabled}
              className="buttons__button buttons__button--next"
              onClick={(e) => handleNextStep(e)}
            >
              Next
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default AccessStore;
