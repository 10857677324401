import React, { Component } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import step1_img from "../../images/access-store/get_storename.jpg";
import shopifyLogo from "../../images/subscribe.png";
import TooltipCompo from "../Tooltip/TooltipCompo";

class SubscribePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showViewer: false,
      currentImage: 0,
      storename: "", // Add the storename state

    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e) {
    e.preventDefault();
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  openImageViewer = (index) => {
    this.setState({
      currentImage: index,
      showViewer: true,
    });
  };

  closeImageViewer = () => {
    this.setState({
      currentImage: 0,
      showViewer: false,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { showViewer, currentImage } = this.state;

    const images = [
      step1_img, 
    ];

    const textTool = "Add the initial store name, you can get this from Shopify settings screen and see your store name mentioned below My Store - i.e. 3ebee1.myshopify.com"

    const storename = this.state.storename;
    const url = `https://admin.shopify.com/store/${storename}/settings/plan`;


    return (
      <div>
        <a
          rel="noopener noreferrer"
          className="btn btn-general"
          onClick={(e) => this.openModal(e)}
          >
          <img src={shopifyLogo} alt="shopify" /> Subscribe
          </a>
        <Popup
          open={this.state.open}
          onClose={this.closeModal}
          modal
          closeOnDocumentClick
          className="guidline-popup"
        >
          <div className="step2_formgroup subscribe-box">
            <div>
              <h4>
               
                <span
                  class="material-symbols-outlined"
                  onClick={this.closeModal}
                >
                  close
                </span>
              </h4>
              
            </div>
            <div className="div_subscribe">
              <label className="form-group__label">
                Enter Store Name <TooltipCompo text={textTool} tooltipId="info-tooltip" />
              </label>
              <input
                type="text"
                value={this.state.storename}
                name="storename"
                onChange={this.handleChange}
                className="form-group__input"
              />

                {storename ? ( // Check if storename is present
                  <a
                    rel="noopener noreferrer"
                    className="btn btn-general"
                    href={url}
                    target="_blank"
                  >
                     Ok
                  </a>
                ) : (
                  // Display a different message or UI when storename is not present
                  <></>
                )}
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default SubscribePopup;
