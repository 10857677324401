import React, { Component } from "react";
import addFile_img from "../images/add-file.png";
import logo_img from "../images/logo.png";
import next_img from "../images/next.png";
import axios from "axios";

class PickLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSkipChecked: false, // Initial state
      isFileChecked: false, // Initial state
    };
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { errorFileMessage, handleData } = this.props;


    const onSelectImageHandler = (e) => {
      // alert(this.state.isChecked);
      var files = e.target.files
      handleData({
        file: files[0],
        skip_logo: false
      });
      if( this.props.storeData.skip_logo ){
        onCheckBoxHandler2(e);
      }
    };

    const onCheckBoxHandler2 = (e) => {
      
      this.setState((prevState) => ({
        isChecked: !prevState.isChecked,
      }), () => {
        const { isChecked } = this.state;
        if(isChecked){
          handleData({
            skip_logo: true
          });
        }else{
          handleData({
            skip_logo: false
          });
        }
      });
    };

    const onCheckBoxHandler = (e) => {

      handleData({
        file: "",
      });
      
      this.setState((prevState) => ({
        isChecked: !prevState.isChecked,
      }), () => {
        const { isChecked } = this.state;
        if(isChecked){
          handleData({
            skip_logo: true
          });
        }else{
          handleData({
            skip_logo: false
          });
        }
      });
    };

    const triggerCheckBoxHandler = (e) => {
      onCheckBoxHandler(e);
    }
    
    const fileUploadSubmit = (e) => {
      e.preventDefault();
      
      var result = new Promise((resolve, reject) => {
        const formData = new FormData();
        // formData.append("access_token", this.state.access_token);
        formData.append("file", this.props.storeData.file);
        // formData.append("storename", this.state.storename);
        // formData.append("theme_id", this.state.theme_id);
  
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
  
        axios
          .post(
            `${process.env.REACT_APP_FRONTEND_URL}api/upload/store/logo`,
            formData,
            config
          )
          .then((response) => {
            if (response.data.status === "error") {
              resolve(response.data);
            } else {
              resolve(response.data); 
            }
          })
          .catch((error) => {
            this.setState({
              submitError: error,
            });
            reject(error); // Reject the promise on axios request error
          });
      });

      console.log(result);
    }

    if( this.props.storeData.skip_logo ){
      var myclassName_3 = "option-box-1 input-checkbox pro_select";
    }else{
       myclassName_3 = "option-box-1 input-checkbox";
    }
    
    if( this.props.storeData.file ){
      var myclassName_1 = "option-box-1 pro_select";
    }else{
       myclassName_1 = "option-box-1";
    }

    return (
      <div className="form-group__element Pick_logo_div">
        <p className="pick_logo_text">Choose Store Logo</p>

        <div className="main-box">
          <div className={myclassName_1}>
            <label for="storeLogo">
              <img src={addFile_img} alt="add file" /> Add File
            </label>
            <input
              type="file"
              id="storeLogo"
              name="storeLogo"
              onChange={(e) => onSelectImageHandler(e)}
              className="form-group__input"
            />
          </div>

          <div className="option-box-1">
            <a
              rel="noopener noreferrer"
              className=""
              href="https://turbologo.com/"
              target="_blank"
            >
              <img src={logo_img} alt="add file" />
              Generate Logo
            </a>
          </div>

          <div className={myclassName_3} onClick={(e) => triggerCheckBoxHandler(e)}>
            <img src={next_img} alt="add file" />
            <div className="d-flex">
              <label for="skipLogo" className="skip-title">
                <input
                  type="checkbox"
                  value=""
                  name="skip_logo"
                  className="form-group"
                  onChange={(e) => onCheckBoxHandler(e)}
                  checked={this.props.storeData.skip_logo}
                />
                Skip For Now
              </label>
            </div>
          </div>
        </div>
        {/* <button onClick={(e) => fileUploadSubmit(e)}> submit </button> */}

        <p className="error">{errorFileMessage}</p>

        {/* <div className="Add_file_div">
          <label className="form-group__label" for="storeLogo">
            <span className="material-symbols-outlined">add</span>
            Add File
          </label> */}
        {/* <input type='file' value={pickLogo} name='storeLogo' onChange={handleChange('pickLogo')} className='form-group__input'/> */}
        {/* <input
            type="file"
            id="storeLogo"
            name="storeLogo"
            onChange={(e) => onSelectImageHandler(e.target.files)}
            className="form-group__input"
          />
        </div> */}
      </div>
    );
  }
}

export default PickLogo;
