import React from "react";
import "../../App.css";
import "../../component/Stepper/stepper.css";
import store_logo from "../../images/store-logo/brandbldr-logo.png";
function Index() {
  return (
    <>
      <main className="main-container main_welcome_screen">
        <div className="main-store-section">
          <div className="logo-section">
            <img src={store_logo} alt="" className="centr-logo" />
          </div>
          <div className="btn-section">
            <a
              rel="noopener noreferrer"
              className="btn btn-general"
              href="/start-building-brand"
            >
              Start Building Brand
            </a>
          </div>
        </div>
      </main>
    </>
  );
}

export default Index;
