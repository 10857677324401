import React, { Component } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import step1_img from "../../images/plan/step1.jpg";
import image_4 from "../../images/plan/step2.jpg";
import SimpleImageViewer from "react-simple-image-viewer";
import shopifyLogo from "../../images/shopify.png";

class GuidLinePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showViewer: false,
      currentImage: 0,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e) {
    e.preventDefault();
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  openImageViewer = (index) => {
    this.setState({
      currentImage: index,
      showViewer: true,
    });
  };

  closeImageViewer = () => {
    this.setState({
      currentImage: 0,
      showViewer: false,
    });
  };

  render() {
    const { showViewer, currentImage } = this.state;
    const images = [step1_img, image_4];

    // const images = [

    // ];

    return (
      <div>
        <span className="guidline" onClick={(e) => this.openModal(e)}>
          How to subscribe?
        </span>

        <Popup
          open={this.state.open}
          onClose={this.closeModal}
          modal
          closeOnDocumentClick
          className="guidline-popup"
        >
          <div className="step2_formgroup">
            <div className="popup_title">
              <h4>
                How to subscribe?
                <span
                  class="material-symbols-outlined"
                  onClick={this.closeModal}
                >
                  close
                </span>
              </h4>
            </div>
            <ul>
              {[
                "You'll be taken to a page where you can choose a plan. The basic Shopify plan should be one of the options available. Click on the 'Choose this plan' button under the Basic Shopify plan.",
                "You'll need to provide your credit card information to set up billing. If you don't have a credit card, you won't be able to proceed with the paid plan, but you can continue with the free trial.",
              ].map((step, index) => (
                <li key={index}>
                  <b>{index + 1}.</b>
                  <div className="title-info">
                    {step}
                    {index < 2 && (
                      <img
                        src={images[index]}
                        className="step-box-custom"
                        alt="step 1"
                        onClick={() => this.openImageViewer(index)}
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
            {showViewer && (
              <SimpleImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                onClose={this.closeImageViewer}
                closeOnClickOutside={true}
              />
            )}
          </div>
        </Popup>
      </div>
    );
  }
}

export default GuidLinePopup;
