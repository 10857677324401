import React from "react";
import Form from "../../component/Form";
import "../../App.css";
import "../../component/Stepper/stepper.css";
import store_logo from "../../images/store-logo/store_logo.png";

function StorebuilderForm(){
return (
    <>
    <main className='main-container'>
       <div className="header_title_div">
       <img src={store_logo} alt="" className="centr-logo" />
       <h1 className='main-container__header'>
          Create a Shopify Store
        </h1>
        <p className='main-container__subheader'>Fill all details and read to access your store.</p>
       </div>
          <Form />
      </main>
    </>
)

}

export default StorebuilderForm;