import React, { Component } from "react";
import StepperCompo from "./Stepper/StepperCompo";
import check_mark_img from "../images/check-mark.png";

class SuccessForm extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (
      <div className="form">
        <form>
          <StepperCompo count={8} />
          <div className="container_card container_card_1 Successfully">
            <div className="form-group__element">
             
                <img src={check_mark_img} alt="sucess card" />
            
              <h4>Congratulations </h4>
             
            <p><b>We are halfway through!</b> <br/>
            You have now given us all the permissions to apply needed
                customizations to your Shopify Store.</p>
            
            </div>

            

            <div className="step2_formgroup">
              <div className="whats_next_box">
                <h4>What's next?</h4>
              </div>
              <ul>
                <li className="next-li">
                  Go to your Shopify Store
                </li>
                <li className="next-li">
                Verify the customizations from <strong>online store</strong> and click <strong>themes &gt; View Your Store.</strong>
                </li>
                <li className="next-li">
                Launch your Shopify Store
                </li>
                <li className="next-li">Enjoy your Launch with Customized happiness from <a href="https://brandbldr.io/">brandbldr.io</a></li>
              </ul>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default SuccessForm;
