import React, { Component } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import step1_img from "../../images/access-store/get_storename.jpg";
import SimpleImageViewer from "react-simple-image-viewer";

class StorenamePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showViewer: false,
      currentImage: 0,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e) {
    e.preventDefault();
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  openImageViewer = (index) => {
    this.setState({
      currentImage: index,
      showViewer: true,
    });
  };

  closeImageViewer = () => {
    this.setState({
      currentImage: 0,
      showViewer: false,
    });
  };

  render() {
    const { showViewer, currentImage } = this.state;

    const images = [
      step1_img, 
    ];
    
    return (
      <div>
        <span className="guidline" onClick={(e) => this.openModal(e)}>
        How to get Store Name?
        </span>
        <Popup
          open={this.state.open}
          onClose={this.closeModal}
          modal
          closeOnDocumentClick
          className="guidline-popup"
        >
          <div className="step2_formgroup">
            <div className="popup_title">
              <h4>
                How to get Store Name?
                <span
                  class="material-symbols-outlined"
                  onClick={this.closeModal}
                >
                  close
                </span>
              </h4>
              
            </div>
            <ul>
              <li>
                <b>1. </b>
                <div className="title-info">
                Add the initial store name, you can get this from Shopify settings screen and see your store name mentioned below My Store  - i.e. 3ebee1.myshopify.com
                {images.map((src, index) => (
                      index < 1 && (
                      <img
                        src={src}
                        onClick={() => this.openImageViewer(index)}
                        key={index}
                        style={{ margin: "2px" }}
                        alt=""
                        className="step-box-custom"
                      />
                      )
                    )
                    )}
                </div>
              </li>
            </ul>
            {showViewer && (
              <SimpleImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                onClose={this.closeImageViewer}
                closeOnClickOutside={true}
              />
            )}
          </div>
        </Popup>
      </div>
    );
  }
}

export default StorenamePopup;
