import React, { Component } from "react";
import list_img from "../images/list.png";
import grid_img from "../images/grid.png";

class StoreDetails extends Component {
  constructor(props) {
    super(props);
    const isDisplay = this.props.storeData.product_display;
    if(isDisplay === "list"){
      var isList =  "pro_select";
      var isGrid =  "";
    }else if(isDisplay === "grid" ){
      var isList =  "";
      var isGrid =  "pro_select";
    }else{
      var isList =  "pro_select";
      var isGrid =  "";
    }
    this.state = {
      listProduct_display: isList,
      gridProduct_display: isGrid,
    };
  }

  continue = (e) => {
    e.preventDefault();
    // this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { product_display, handleChange, handleData } = this.props;

    const onProductDisplay = (e) => {
      if (e.target.value === "list") {
        this.setState({ listProduct_display: "pro_select" });
        this.setState({ gridProduct_display: "" });
      } else if (e.target.value === "grid") {
        this.setState({ gridProduct_display: "pro_select" });
        this.setState({ listProduct_display: "" });
      } else {
        this.setState({ listProduct_display: "" });
        this.setState({ gridProduct_display: "" });
      }
      handleData({ product_display: e.target.value });
    };

    return (
      <div>
        <div className="div_business Product_Display">
          {/* <label className="form-group__label">Product Display:</label> */}
          <p className="pick_logo_text">Product Display</p>

          <div className="main_view_card_div">
            <div className={`View_div ${this.state.listProduct_display}`}>
              <img src={list_img} alt="enter credit card" />
              <input
                type="radio"
                data-productdisplay="list"
                value="list"
                name="product_display"
                onChange={(e) => onProductDisplay(e)}
              />
              <label>List View</label>
            </div>

            <div className={`View_div ${this.state.gridProduct_display}`}>
              <img src={grid_img} alt="enter credit card" />
              <input
                type="radio"
                data-productdisplay="grid"
                value="grid"
                name="product_display"
                onChange={(e) => onProductDisplay(e)}
              />
              <label> Grid View</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StoreDetails;
