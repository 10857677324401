import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PersonIcon from "@material-ui/icons/Info";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  customTooltip: {
    fontSize: "14px", // Adjust the font size as needed
  },
});

class TooltipCompo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { text, tooltipId } = this.props;
    const { classes } = this.props;

    return (
      <Tooltip title={text} style={{color:"#003a6b"}} classes={{ tooltip: classes.customTooltip }} placement="right">
        <IconButton>
          <PersonIcon />
        </IconButton>
      </Tooltip>
    );
  }
}

export default withStyles(styles)(TooltipCompo);