import React, { Component } from "react";
import Popup from "reactjs-popup";
import axios from "axios";
import "reactjs-popup/dist/index.css";
import shopifyLogo from "../../images/shopify.png";
import SweetAlert2 from "sweetalert2";

class AccessStorePopup extends Component {
  constructor(props) {
    super(props);
    
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    const isMobile = window.innerWidth < 480;
    this.state = {
      open: false,
      swal: {},
      isMobileView: isMobile,
    };
    // alert(isMobile);
  }

  openModal(e) {
    e.preventDefault();
    this.setState({ open: true });
  }

  closeModal() {
    this.setState({ open: false });
  }

  handleAccessToken = (e) => {
    e.preventDefault();
    this.setState({
      swal: {
        show: true,
        title: "Access token",
        text: "Do you want to continue with disktop ?",
        html:
          '<label className="form-group__label">Email</label>' +
          '<input id="email-input" type="text" value="" name="email" className="form-group__input" />',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      },
    });
  };


    // Function to open the SweetAlert2 dialog
    openSwal = (e) => {
      e.preventDefault();

      SweetAlert2.fire({
        show: true,
        html:
          '<h3>Enter an Email to recieve a link to Sign Up?</h3>'+
          '<input id="email-input" class="swal2-input" type="email" placeholder="Email">',
        showCancelButton: true,
        showCloseButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const emailInput = document.getElementById('email-input');
          const email = emailInput.value;
  
          if (email) {
            try {
              const config = {
                headers: {
                  "Content-Type": "application/json",
                },
              };
  
              const response = await axios.get(
                `${process.env.REACT_APP_FRONTEND_URL}api/send/mail?access_token_email=${email}`,
                config
              );
  
              if (response.data.status === "error") {
                // Handle the error case
              } else if (response.data.status === "success") {
                // Handle the success case
                SweetAlert2.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Mail sent successfully.',
                });
              }
            } catch (error) {
              // Handle any network or request error here
              console.error("API Request Error:", error);
            }
          } else {
            // Email is empty, show an alert
            SweetAlert2.fire({
              icon: 'error',
              title: 'Error',
              text: 'Email id required.',
            });
          }
        }
      });
    };
  

  // getEmailError = (e) => {
  //   e.preventDefault();
  //   this.setState({
  //     swal: {
  //       icon: 'error',
  //       title: 'Error',
  //       text: 'Email cannot be empty.',
  //     },
  //   });
  // };


  render() {

    return (
      <div>
        {/* <a
          rel="noopener noreferrer"
          className="btn btn-general"
          href="https://shopify.pxf.io/c/3761361/1101159/13624"
          target="_blank"
          onClick={(e) => this.openModal(e)}
        >
          <img src={shopifyLogo} alt="shopify" /> Access Shopify
        </a> */}
        {this.state.isMobileView ? (
          <a className="btn btn-general" onClick={(e) => this.openModal(e)}>
            <img src={shopifyLogo} alt="shopify" /> Access Shopify
          </a>
        ) : (
          <a
            rel="noopener noreferrer"
            className="btn btn-general"
            href="https://shopify.pxf.io/c/3761361/1101159/13624"
            target="_blank"
          >
            <img src={shopifyLogo} alt="shopify" /> Access Shopify
          </a>
        )}
        <Popup
          open={this.state.open}
          onClose={this.closeModal}
          modal
          closeOnDocumentClick
        >
          <div className="show-box">
            <h4>We recommended you to use Desktop, I want to continue on Mobile.</h4>
            <a
              className="btn btn-general"
              onClick={(e) => this.openSwal(e)}
            >
              Yes
            </a>
            <a
              className="btn btn-general"
              onClick={this.closeModal}
            >
              No
            </a>
           
          </div>
        </Popup>
      </div>
    );
  }
}

export default AccessStorePopup;
